import Head from "next/head";
import {useRouter} from "next/router";
import { SWRConfig } from "swr";
import "../styles/core.scss";
import { useEffect } from "react";
import gsap from "scripts/gsap/gsap-core.js";
import CSSPlugin from "scripts/gsap/CSSPlugin";
import Script from "next/script";

function localStorageProvider() {
  if (typeof window === "undefined") {
    return new Map([]);
  }
  //when initializing, we restore the data from `localStorage` into a map.
  const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

  //before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("app-cache", appCache);
  });

  //we still use the map for write & read for performance.
  return map;
}

function MyApp({ Component, pageProps: {session, ...pageProps} }) {
  const router = useRouter();

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceWorker.js").then(function(registration) {
        //console.log("Service Worker registration successful with scope: ", registration.scope);
      },
      function(err) {
        //console.log("Service Worker registration failed: ", err);
      });
    }
  }, []);

  //redirect uppercase routes to lowercase
  //https://github.com/vercel/next.js/issues/21498
  function checkUppercase(str) {
    for (var i = 0; i < str.length; i++) {
      if (
        str.charAt(i) === str.charAt(i).toUpperCase() &&
        str.charAt(i).match(/[a-z]/i)
      ) {
        return true;
      }
    }
    return false;
  }


  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, minimum-scale=1.0, initial-scale=1.0"/>
      </Head>
      <SWRConfig value={{ provider: localStorageProvider }}>
        <Script id="googleAnalytics" strategy="afterInteractive">
          {`setTimeout(()=>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K6B6QDT');}, 1000)`}
        </Script>
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp;
